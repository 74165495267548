

// GLOBAL TRANSITION
@mixin global-transition($speed) {
  transition: all $speed ease-in;
}


// CENTER ALIGN
@mixin vertical-center {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}

@mixin horizontal-center {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

@mixin absolute-center {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}


// REMs
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@function calculateUnitless($size) {
  $unitSize: $size / 16px;
  @return $unitSize;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: calculateUnitless($size);
}

@mixin font-units($fontSize, $lineHeight) {
  font-size: calculateRem($fontSize);
  line-height: $lineHeight / $fontSize;
}


// TRIANGLE
@mixin triangle-down($size, $color) {
  content: '';
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-top: $size solid $color;
}

@mixin triangle-up($size, $color) {
  content: '';
  width: 0;
  height: 0;
  border-left: $size solid transparent;
  border-right: $size solid transparent;
  border-bottom: $size solid $color;
}
