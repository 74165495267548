/**
Class Naming Structure: block--element__modifier
**/

$sans: 'gill-sans-nova', Helvetica, arial, sans-serif;
$mono: 'input-mono', monospace;

$base: 24px;

$weight-light:  300;
$weight-book:   400;
$weight-medium: 600;
$weight-bold:   700;
$weight-black:  900;

body {
  font: $weight-book 1em/1.5em $sans;
}

h1,h2,h3,h4,h5 {
  text-align: center;
  line-height: 1;
}

strong, b {
  font-weight: $weight-bold;
}

em {
  font-style: italic;
}

.title__h1 {
  @include font-units(35px, 44px);

  @include bp-min-width($medium) {
    @include font-units(40px, 44px);
  }
}

.title__h2 {
  @include font-units(18px, 28px);

  @include bp-min-width($medium) {
    @include font-units(20px, 32px);
  }
}

.title__h3 {
  @include bp-min-width($medium) {
  }
}

.title__h4 {
  @include bp-min-width($medium) {
  }
}

.title__h5 {
  @include bp-min-width($medium) {
  }
}

.body-content h1,
.body-content h2,
.body-content h3,
.body-content h4 {
}

.body-content p,
.body-content ul,
.body-content ol {
}

.body-content p:not(:last-child),
.body-content ul:not(:last-child),
.body-content ol:not(:last-child),
.body-content blockquote:not(:last-child) {
}

.body-content blockquote {
}
