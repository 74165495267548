
body,
html {
  height: 100%;
}

body {
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: $bg-body;
  background-attachment: fixed;
  color: #fff;

  @include bp-min-width($medium) {
    background-image: $bg-body-2x;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: $base*2 20px;

  @include bp-min-width($tiny) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include bp-min-width($small) {
    max-width: 410px;
  }

  @include bp-min-width($medium) {
    height: 100%;
  }
}

.main {
  width: 100%;
}
