
.landing__title {
	margin-bottom: 12px;
}

.landing__subtitle {
	margin-bottom: $base*4;
}

.newsletter-form {
	position: relative;
	border-bottom: 1px solid #fff;
}

.newsletter-form__email {
	margin-right: 104px;
	padding-bottom: 6px;
	border-right: 1px solid #fff;
}

.newsletter-form__input {
	height: 40px;
}

.newsletter-form__submit {
	position: absolute;
	top: 0;
	right: 0;
}

.newsletter-form__btn {
	@include font-size(13px);
	width: 104px;
	height: 40px;
  background: none;
  font-family: $mono;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1em;

	@include bp-min-width($medium) {
		@include font-size(15px);
	}
}

div.mce_inline_error {
	@include font-units(14px, 21px);
	position: absolute;
	left: 0;
	top: 54px;
	padding-left: 14px;
	color: rgb(251, 40, 40);
}

.mce-responses {
	@include font-units(11px, 18px);
	position: absolute;
	top: 54px;
	left: 0;
	right: 0;
	width: 100%;
	font-family: $mono;
	text-align: center;
	letter-spacing: .1em;
	text-transform: uppercase;
}

.mce-responses a {
	color: #fff;
}
