* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

input {
  -webkit-appearance: none;
  border-radius: 0; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle; }

iframe {
  width: 100%; }

object {
  max-width: 100%; }

a {
  text-decoration: none;
  outline: none; }

/**
Class Naming Structure: block--element__modifier
**/
body {
  font: 400 1em/1.5em "gill-sans-nova", Helvetica, arial, sans-serif; }

h1, h2, h3, h4, h5 {
  text-align: center;
  line-height: 1; }

strong, b {
  font-weight: 700; }

em {
  font-style: italic; }

.title__h1 {
  font-size: 2.1875rem;
  line-height: 1.25714; }
  @media (min-width: 50em) {
    .title__h1 {
      font-size: 2.5rem;
      line-height: 1.1; } }

.title__h2 {
  font-size: 1.125rem;
  line-height: 1.55556; }
  @media (min-width: 50em) {
    .title__h2 {
      font-size: 1.25rem;
      line-height: 1.6; } }

svg:not(:root) {
  overflow: hidden; }

[class^="icon-"] {
  display: inline-block;
  vertical-align: middle; }

body,
html {
  height: 100%; }

body {
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/dist/images/bg-body.png");
  background-attachment: fixed;
  color: #fff; }
  @media (min-width: 50em) {
    body {
      background-image: url("/dist/images/bg-body-2x.png"); } }

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 48px 20px; }
  @media (min-width: 21.875em) {
    .page-wrapper {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 37.5em) {
    .page-wrapper {
      max-width: 410px; } }
  @media (min-width: 50em) {
    .page-wrapper {
      height: 100%; } }

.main {
  width: 100%; }

a {
  transition: all 0.1s ease-in; }

.btn {
  display: inline-block; }

input[type="submit"],
input[type="button"],
button {
  transition: all 0.1s ease-in;
  border: none;
  outline: none;
  cursor: pointer; }

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"],
textarea {
  font-size: 0.9375rem;
  width: 100%;
  padding: 0 14px;
  border: none;
  background: none;
  outline: none;
  color: #fff;
  font-family: "gill-sans-nova", Helvetica, arial, sans-serif;
  line-height: normal; }

textarea {
  width: 100%;
  height: 96px;
  padding: 12px 20px;
  border: 1px solid #464646;
  outline: none;
  font-family: "gill-sans-nova", Helvetica, arial, sans-serif;
  font-size: 1em;
  line-height: normal; }

::placeholder {
  color: #fff; }

/** Errors ============**/
.header {
  margin-bottom: 60px; }

.footer {
  padding-top: 48px; }
  @media (min-width: 50em) {
    .footer {
      padding-top: 60px; } }

.landing__title {
  margin-bottom: 12px; }

.landing__subtitle {
  margin-bottom: 96px; }

.newsletter-form {
  position: relative;
  border-bottom: 1px solid #fff; }

.newsletter-form__email {
  margin-right: 104px;
  padding-bottom: 6px;
  border-right: 1px solid #fff; }

.newsletter-form__input {
  height: 40px; }

.newsletter-form__submit {
  position: absolute;
  top: 0;
  right: 0; }

.newsletter-form__btn {
  font-size: 0.8125rem;
  width: 104px;
  height: 40px;
  background: none;
  font-family: "input-mono", monospace;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .1em; }
  @media (min-width: 50em) {
    .newsletter-form__btn {
      font-size: 0.9375rem; } }

div.mce_inline_error {
  font-size: 0.875rem;
  line-height: 1.5;
  position: absolute;
  left: 0;
  top: 54px;
  padding-left: 14px;
  color: #fb2828; }

.mce-responses {
  font-size: 0.6875rem;
  line-height: 1.63636;
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  width: 100%;
  font-family: "input-mono", monospace;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase; }

.mce-responses a {
  color: #fff; }
