
input[type="text"],
input[type="email"],
input[type="phone"],
input[type="password"],
textarea {
  @include font-size(15px);
  width: 100%;
  padding: 0 14px;
  border: none;
  background: none;
  outline: none;
  color: #fff;
  font-family: $sans;
  line-height: normal;
}

textarea {
  width: 100%;
  height: $base*4;
  padding: 12px 20px;
  border: 1px solid $grey;
  outline: none;
  font-family: $sans;
  font-size: 1em;
  line-height: normal;
}

::placeholder {
  color: #fff;
}

/** Errors ============**/

.form-error {}
